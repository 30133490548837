export default {
  // ? =============================
  // ? =======  reuseables  ========
  // ? =============================

  title: {
    fontSize: ['2rem', '', '', '2.5rem'],
    order: '2',
    textTransform: 'uppercase',
    padding: '1rem 0rem',
    width: 'fit-content'
  },
  subtitle: {
    fontWeight: '300',
    opacity: '0.8',
    margin: '0rem 0rem 1.5rem',
    order: '1'
  },

  text: {
    order: '3',
    fontSize: '0.9rem',
    lineHeight: '2',
    em: {
      fontFamily: 'handwriting',
      fontSize: '2em',
      letterSpacing: '2px'
    }
  },

  ctaButton: {
    order: '4',
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  brownBackground: {
    content: "''",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(118,94,79,0.65)'
  },

  // ? ==========================
  // ? =======  Layout  =========
  // ? ==========================

  openingPage: {
    backgroundColor: 'backgroundSecondary'
  },

  navigation: {
    '> .container': {
      padding: ['0.5rem', '0.5rem', '0.5rem', '4.5rem 1rem'],
      '.smallNavMenu': {
        '.react-reveal': {
          display: 'flex',
          flexDirection: 'row'
        },
        '.navItemDropdownMenu': {
          background: 'transparent'
        }
      }
    },
    '.containerScrolled': {
      backgroundColor: 'backgroundSecondary',
      '.logoLocationContainer': {
        // display: 'none',
        margin: ['', '', '', 'auto auto auto 1rem'],
        position: ['', '', '', 'static'],
        padding: '0rem',
        '.container': {
          // padding: ['0.5rem'],
        },
        '.logoScrolled ': {
          maxWidth: ['110px', '', '', '150px'],
          marginBottom: '0rem',
          maxHeight: 'unset',

          display: 'block',
          padding: '0rem',
          a: {
            display: 'flex'
          },
          img: {
            maxHeight: 'unset',
            width: '100%',

            // maxWidth: ['105px', '130px', '150px'],
            height: 'unset',
            padding: '0rem'
          }
        },
        '.locationSwitcher': {
          justifyContent: 'center',
          button: {
            fontSize: '0.8rem'
          }
        },
        '.popUp': {
          transform: 'unset'
        }
      }
    },
    '.logoLocationContainer': {
      // display: 'none',
      margin: ['', '', '', '0 auto 0 0'],
      position: ['', '', '', 'static'],
      '.container': {
        // padding: ['0.5rem'],
      },
      '.logo': {
        maxWidth: '150px',
        marginBottom: '0rem',
        img: {
          filter: 'unset'
        }
      },
      '.locationSwitcher': {
        justifyContent: 'center',
        button: {
          fontSize: '0.8rem'
        }
      },
      '.popUp': {
        transform: 'unset'
      }
    },

    '.smallNavMenu': {
      mt: '1.25rem',
      '.react-reveal': { display: 'flex', flexDirection: 'row' },
      '.navItemDropdownMenu': {
        background: 'black',
        width: 'fit-content'
      },
      '.navItem': {
        color: 'white',
        margin: ['', '', '', '0.5rem', '0.5rem'],
        a: {
          fontSize: ['1rem', '', '', '0.8rem', '1rem'],
          letterSpacing: '1px',
          ':hover': {
            color: 'lightgrey',
            textDecoration: 'underline'
          }
        }
      },
      '.reservations': {
        // margin: '0rem',
        a: {
          padding: '0.5rem',
          border: 'solid 1px',
          borderColor: 'primary'
        }
      }
    },
    '.hamburger': {
      display: ['', '', '', 'none'],
      backgroundColor: 'rgb(0 0 0 / 42%)',
      div: {
        backgroundColor: 'white'
      }
    },
    '.navMenu': {
      left: 'unset',
      right: '0rem',
      transform: 'unset',
      width: ['85%', '60%', '50%', '40%'],
      '.navMenuLogo': {
        position: ['', '', '', ''],
        width: ['', '', '', '100%'],
        maxWidth: ['150px', '', '250px'],
        left: '1rem',
        margin: '0rem 0.5rem 2rem',
        img: {
          maxHeight: ['', '', '', 'unset'],
          maxWidth: ['', '', '', 'unset'],
          width: ['', '', '', '100%']
        }
      },
      '.phoneSocialContainer': {
        flexDirection: ['column', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        '> li': {
          width: '100%'
        },
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem'
        }
      },

      '.socialIconsContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        svg: {
          filter: 'brightness(0) invert(1)'
        },
        '.smallNavHeading ': {
          textAlign: 'right'
        },
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem'
        }
      }
    },
    '.navMenuOpen': {
      alignItems: ['', '', '', 'flex-end'],
      padding: ['', '', '', '4rem'],
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundColor: 'backgroundSecondary',
      width: ['85%', '60%', '50%', '40%'],
      color: 'light',
      '.navItemDropdownMenu': {
        position: 'static',
        background: 'transparent'
      },
      '.navItem': {
        textAlign: 'right',
        a: {
          fontSize: '1.2rem'
        }
      },
      '.navMenuLogo': {
        position: ['', '', '', ''],
        width: ['', '', '', '100%'],
        maxWidth: ['150px', '', '250px'],
        left: '1rem',
        margin: '0rem 0.5rem 2rem',
        img: {
          maxHeight: ['', '', '', 'unset'],
          maxWidth: ['', '', '', 'unset'],
          width: ['', '', '', '100%']
        }
      },
      '.seperatorLine': {
        margin: ['', '', '', '1rem 0 1rem auto']
      },
      '.phoneSocialContainer': {
        flexDirection: ['column', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        '> li': {
          width: '100%'
        },
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem'
        }
      },
      '.smallNavHeading ': {
        textAlign: ['', '', '', 'right']
      },

      '.socialIconsContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        svg: {
          filter: 'brightness(0) invert(1)'
        },
        '.smallNavHeading ': {
          textAlign: 'right'
        },
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem'
        }
      }
    },

    '.navBlockOpen': {
      width: ['15%', '40%', '50%', '60%'],
      left: '0rem',
      backgroundImage: 'url(https://res.cloudinary.com/gonation/image/upload/v1675711026/sites/esh/nav-bg.jpg)',
      backgroundSize: 'cover',
      backgroundPosition: 'center center'
    }
  },

  footer: {
    padding: ['0rem', '', '', '0rem'],
    backgroundColor: 'backgroundSecondary',
    borderTop: '#fafafa 2px solid',
    color: 'texwhitet',
    display: 'flex',
    flexDirection: 'column',
    '::after': {
      content: `"© ${new Date().getFullYear()} esh"`,
      width: '100%',
      backgroundColor: '#202020',
      color: 'white',
      padding: '1rem 0rem',
      marginBottom: ['40px', '', '', '0px'],
      textTransform: 'uppercase',
      order: '6',
      textAlign: 'center',
      fontFamily: 'heading'
    },
    '.logo': {
      maxWidth: '200px'
      // filter: 'brightness(0) invert(1)',
    },
    '.contactDetails-container': {
      margin: '2rem 0rem'
    },
    '.sectionHeading': {
      borderBottom: '0rem',
      fontSize: '1.5rem',
      fontFamily: 'body',
      textTransform: 'uppercase',
      marginBottom: ['0rem', '', '', '1rem']
    },
    '.column': {
      marginBottom: '0rem',
      padding: '3rem 2rem',
      width: ['', '', '', '20%'],
      flexGrow: '1',
      maxWidth: ['unset', '', '', '350px']
    },
    '.contactColumn': {
      borderRight: 'none !important',
      padding: '3rem 2rem !important'
    },
    '.exploreColumn': {
      order: ['5', '', '', '5'],
      backgroundColor: 'black',
      margin: ['', '', '', '0 0 0 auto'],
      marginBottom: '0rem',
      padding: ['', '', '4rem 3rem', '5rem 4rem'],
      width: ['100%', '', '', '40%'],
      '.sectionHeading': {
        color: 'white',
        fontSize: '2rem',
        textAlign: 'left',
        width: '100%',
        padding: ['1.25rem', '', '', '0rem'],
        fontWeight: '400'
      },

      '.multiButtonContainer': {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        a: {
          width: '100%',
          color: 'primary',
          fontSize: ['1rem', '', '1.25rem'],
          letterSpacing: '1px',
          fontWeight: '600',
          padding: '0.25rem 0rem'
        }
      }
    },
    '.gonationLogo': {
      filter: 'brightness(0) invert(1)'
    },
    '.copyright': {
      display: 'none'
    },

    '.socialContainer svg path': {
      fill: 'white'
    },
    '.footerAboutButton span': {
      variant: 'buttons.primary',
      fontSize: '0.9rem',
      padding: '0.75rem 1.5rem'
    },
    '.button': {
      variant: 'buttons.primary',
      borderRadius: '500px',
      padding: '0.75rem 1.5rem'
    },
    '.aboutText': {
      // color: 'white',
    },
    '.popUp': {
      '.content': {
        padding: '3rem 1rem',
        '.title': {
          fontSize: '1.5rem',
          opacity: '0.7'
        }
      },
      '.locations': {
        a: {
          variant: 'buttons.primary'
        }
      }
    }
  },

  ctaWidget: {
    zIndex: '900'
  },

  sideBySide1: {
    position: 'relative',
    minHeight: ['', '', 'calc(75vh - 77px)'],
    '.content': {
      padding: ['1rem', '', '', '4rem', '5rem']
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      variant: 'customVariants.ctaButton'
    }
  },

  // ? =======================
  // ? =====  Homepage  ======
  // ? =======================

  homepageHero: {
    '.hero_content_container': {
      margin: 'auto',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      zIndex: '1'
    },
    '.logoHero': {
      maxHeight: '180px',
      display: 'none'
    },
    '.title': {
      variant: 'customVariants.title',
      color: 'light',
      textAlign: 'center',
      textShadow: '2px 2px 10px black'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },

    '.slick-slider': {
      // '::before': {
      //   variant: 'customVariants.brownBackground'
      // }
    }
  },
  homepageShout: {
    // backgroundColor: 'background',
    // padding: ['0rem 1rem 1rem', '', '', '0rem 0.5rem 1rem'],
    // marginTop: ['1rem', '', '', '0rem'],
    // '.title': {
    //   border: ['', '', '', 'solid 0.5rem'],
    //   borderColor: ['', '', '', 'white'],
    //   backgroundColor: 'secondary',
    //   fontFamily: 'body'
    // },
    // '.text': {
    //   fontFamily: 'heading',
    //   fontSize: ['1.76rem', '', '2.5rem'],
    //   order: ['4', '', '3'],
    //   border: ['', '', '', 'solid 0.5rem'],
    //   borderColor: ['', '', '', 'white'],
    //   fontWeight: 'normal',
    //   padding: ['', '', '1.5rem'],
    //   backgroundColor: ['#592322', '', '', '#592322'],
    //   color: '#e5d5c1'
    // },
    '.date': {
      //   display: 'none',
      backgroundColor: '#202020',
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      fontSize: '0.8rem',
      display: 'none'
    }
    // '.imageContainer': {
    //   border: ['', '', '', 'solid 0.5rem'],
    //   borderColor: ['', '', '', 'white'],
    //   order: ['', '', '4'],
    //   cursor: 'pointer'
    // },
    // '.shoutCTAS': {
    //   border: 'solid 8px',
    //   borderColor: 'background'
    // }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1',
    background: 'url(https://res.cloudinary.com/gonation/image/upload/v1675707465/sites/esh/textured-bg.png)'
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide1',
    color: 'white',
    '::before': {
      variant: 'customVariants.brownBackground'
    },
    '.section': {
      zIndex: '1'
    }
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide1',
    padding: '3rem',
    height: 'calc(60vh + 6rem)',
    minHeight: 'unset',
    '.slick-initialized .slick-slide > div': {
      height: '60vh'
    },
    '.slick-slide img': {
      height: '60vh'
    },
    '.slick-arrow': {
      display: 'none!important'
    }
  },

  imageBoxes: {
    position: 'relative',
    padding: '2rem',

    '.box': {
      padding: '0rem',
      width: ['100%', '', 'calc(50% - 2rem)', 'calc(50% - 2rem)'],
      position: 'relative',
      height: '450px',
      background: 'transparent',
      color: 'white',
      margin: '0rem',
      borderRadius: '0px',
      margin: '1rem'
    },
    '.image': {
      position: 'absolute',
      height: '450px',
      zIndex: '-1',
      objectFit: 'cover'
    },
    '.title': {
      padding: '0rem 2rem',
      order: '2',
      textTransform: 'uppercase',
      fontSize: ['1.5rem', '1.75rem', '2rem', '', '2.25rem']
    },
    '.subtitle': {
      margin: '3rem 2rem 1rem',
      padding: '1rem 0rem',
      borderBottom: '2px solid white',
      width: 'calc(100% - 4rem)',
      textTransform: 'uppercase',
      order: '1',
      fontWeight: '400'
    },
    '.text': {
      padding: '0rem 2rem',
      order: '3',
      color: 'white',
      margin: '1rem 0rem 2rem'
    },
    '.ctaLink': {
      margin: '0rem 2rem 3rem',
      order: '4',
      border: 'solid 2px white',
      backgroundColor: 'transparent',
      width: 'fit-content',
      borderRadius: '0px',
      textTransform: 'uppercase',
      padding: '0.5rem 2rem',
      fontSize: '0.9rem',
      fontWeight: 'bold',
      ':hover': {
        backgroundColor: 'primary',
        borderColor: 'primary'
      }
    }
  },

  homepageEvents: {
    variant: 'customVariants.sideBySide1',
    color: 'white',
    'div.section': {
      a: {
        '.ctaButton': {
          borderColor: 'light',
          color: 'light'
        }
      }
    }
  },

  homepageContact: {
    variant: 'customVariants.sideBySide1',
    // color: 'white',
    '.section': {
      backgroundColor: 'background',
      p: 3,
      height: '100%',
      margin: 'auto'
    }
  },

  //  ? ======================
  //  ? ====  About page  ====
  //  ? ======================

  aboutSection: {
    '.title': {
      fontSize: '3.5rem',
      lineHeight: '1',
      color: 'primary'
    }
  },

  aboutPageSlider: {
    padding: '0rem',
    '.slick-dots': {
      display: 'none !important'
    }
  },

  //  ? ========================
  //  ? ======  Menu page  =====
  //  ? ========================

  menu: {
    // border: 'solid 1px black',
    padding: ['1rem', '1rem', '1rem', '1rem'],
    margin: ['1rem 0.5rem', '', '', '', '1rem auto 3rem'],
    width: ['calc(100% - 1rem)'],
    '.backToMenuBtn': {
      mb: '2rem',
      fontFamily: 'body',
      textTransform: 'uppercase',
      fontWeight: 'bold'
    },
    backgroundColor: 'transparent',
    '.section-cell': {
      // flexGrow: '1',
      position: 'relative',
      color: 'white',
      borderRadius: '0px'
    },
    '.menuContainer': {
      paddingBottom: '1rem'
    },
    '.tabsRow': {
      marginBottom: '2rem',
      display: 'none'
    },
    '.dropdownContainer': {
      display: 'none'
    },
    '#menuTab': {
      flexGrow: '1',
      width: 'fit-content',
      flexGrow: '1',
      display: 'flex',
      maxWidth: 'unset',
      minWidth: 'unset',
      textAlign: 'center',
      fontWeight: 'bold',
      justifyContent: 'center',
      ':hover': {
        backgroundColor: 'primary',
        opacity: '0.7'
      }
    },
    '.menuItemInnerContainer, .menuItemContentContainer': {
      margin: '0rem',
      '> div': {
        flexWrap: 'wrap'
      }
    },
    '.menuSectionTitle': {
      margin: '1rem 0rem 0rem',
      borderBottom: '2px solid black',
      padding: '0 1rem 1rem',
      textTransform: 'uppercase',
      fontSize: ['1.5rem', '', '2rem'],
      textAlign: 'left',
      color: 'secondary'
    },
    '.menuSectionDescription': {
      margin: '0rem auto 1.5rem',
      fontSize: '1.1rem',
      maxWidth: '800px'
    },
    '.menuItemDescription': {
      // color: 'primary',
    },
    // '.menuPriceContainer': {
    //   display: 'none',
    // },
    // '.variantsContainer': {
    //   display: 'none',
    // },

    '.section-cell': {
      minWidth: '30%'
    },
    '.menuCell': {
      border: 'none',
      backgroundColor: '#CD6E4C',
      color: 'white',
      padding: '2rem 3rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '.cellImageContainer': {
        display: 'none'
      },
      ':hover': {
        backgroundColor: 'secondary'
      }
    },
    '.cellName': {
      // position: 'absolute',
      // top: '50%',
      // left: '50%',
      // transform: 'translate(-50%,-50%)',
      fontFamily: 'body',
      textTransform: 'uppercase',
      fontSize: '2rem',
      margin: '0 auto'
    },
    '.cellImage': {
      filter: 'brightness(0.6)',
      borderRadius: '0px',
      display: 'none'
    },

    '.menuItemContainer, .menuItemContainerImgActive': {
      m: '0rem',
      borderBottom: '1px solid',
      borderColor: 'secondary'
      // margin: '0.5rem',
    },
    '.menuItemNameDescriptionContainer': {
      flexDirection: 'column',
      padding: '0.5rem',
      width: 'calc(100% - 35px)'
    },

    '.menuItemName': {
      textTransform: 'uppercase',
      fontFamily: 'heading',
      letterSpacing: '2px',
      fontSize: '1.25rem',
      mb: '0rem',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    '.menuItemContentContainer': {
      width: 'auto',
      '> div': {
        height: 'inherit'
      }
    },
    '.menuPriceContainer': {
      width: '35px',
      borderLeft: '1px solid',
      borderRight: '1px solid',
      flexGrow: 'unset',
      marginLeft: 'auto',
      flex: 'none',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    '.menuItemPrice': {
      fontWeight: 'bold'
    },
    '.itemVariantsContainer': {
      padding: '0.5rem',
      fontWeight: 'bold',
      color: 'primary'
    },
    '#sec-j9Fm3fDjT2SFl-Lr_X7JCA, #sec-mYmXDAdnQNqcgtZC3xElxQ': {
      padding: '2.5vw 5vw',
      my: '5vw',
      borderRadius: '30px',
      border: 'solid 4px',
      borderColor: 'secondary',
      '.menuSectionTitle': {
        border: 'none',
        mb: '2rem'
      },
      '.menuItemsContainer': {
        flexDirection: 'column'
      },
      '.menuItemContainerImgActive': {
        width: '100%',
        margin: '0rem'
      },
      '.menuItemName': {
        fontSize: ['1rem', '1.1rem']
      }
    }
  },

  menuCTAS: {
    '.ctaLink': {
      variant: 'buttons.primary',
      borderRadius: '0px',
      margin: '0 auto'
    }
  },

  //  ? ========================
  //  ? ====  Events page  ====
  //  ? ========================

  events: {
    '.logo': {
      filter: 'brightness(0)'
    },
    '.eventItemImage': {
      minHeight: 'unset'
    },
    '.eventDays, .eventDate': {
      color: 'black'
    },
    '.modalEventTime, .modalEventDate, .modalEventDescription, .modalEventName': {
      color: 'white'
    },
    '.modal .eventDays': {
      color: 'white'
    },
    '.modal path': {
      fill: 'primary'
    },
    '.modal': {
      maxWidth: 900,
      overflow: 'scroll',
      height: '100%',
      bg: 'dark'
    }
  },

  //  ? ========================
  //  ? ====  Gallery page  ====
  //  ? ========================

  gallery: {
    '.albumsContainer': {
      display: 'none'
    },
    '.albumTitle': {
      variant: 'buttons.secondary',
      backgroundColor: 'transparent',
      color: 'black'
    },
    '.albumName': {
      display: 'none'
    }
  },

  //  ? ========================
  //  ? ====  Contact page  ====
  //  ? ========================

  contactForm: {
    padding: '6rem 1rem',
    order: '4',
    backgroundColor: '#f0f0ee',
    '.title': {
      textTransform: 'uppercase',
      fontSize: '2rem',
      borderTop: '1px solid black',
      borderBottom: '1px solid black',
      padding: '0.5rem',
      marginBottom: '2rem'
    },
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1.25rem',
      opacity: '0.7',
      fontWeight: '200',
      color: 'black',
      marginBottom: '2rem'
    },
    '.text': {},
    form: {
      input: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid black',
        margin: '1.5rem 0rem'
      },

      textarea: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid black',
        margin: '1.5rem 0rem'
      },
      '.submitBtn': {
        borderRadius: '0px',
        color: 'white'
      }
    }
  },

  locationMap: {
    order: '3',
    backgroundColor: '#e4e4e4',
    '.content_container': {
      padding: '4rem 1rem'
    },
    h3: {
      textTransform: 'uppercase',
      fontSize: '2rem',
      borderTop: '1px solid black',
      borderBottom: '1px solid black',
      padding: '0.5rem'
    },

    '.dayofWeekText': {
      margin: '0 auto 0 0'
    }
  },

  //  ? =================================
  //  ? =====  Private Events Page  =====
  //  ? =================================

  privateEventsIntro: {
    minHeight: '50vh',
    '.secondaryCtaButton': {
      boxSizing: 'border-box',
      margin: 0,
      minWidth: 0,
      backgroundColor: 'transparent',
      color: 'var(--theme-ui-colors-primary)',
      border: 'solid 1px',
      borderColor: 'var(--theme-ui-colors-primary)',
      fontWeight: 500,
      padding: '0.75rem 1.5rem',
      fontSize: '1rem',
      textTransform: 'capitalize',
      display: 'flex',
      ':hover': {
        backgroundColor: 'backgroundSecondary',
        color: 'primary',
        borderColor: 'backgroundSecondary',
        opacity: 1
      }
    }
  },
  privateEventsForm: {
    justifyContent: 'center',
    py: '5vh',
    label: {
      mb: '0.25rem'
    },
    input: {
      padding: '0.5rem',
      borderRadius: '5px'
    },
    textarea: {
      padding: '0.5rem',
      borderRadius: '5px'
    },
    select: {
      padding: '0.5rem',
      borderRadius: '5px'
    }
  },

  //  ? =============================
  //  ? =====  Gift Cards Page  =====
  //  ? =============================

  giftcardSection: {
    background: 'none',
    zIndex: '10',
    margin: '0rem auto',
    paddingTop: '0rem',
    '.title': {
      position: 'relative',
      textTransform: 'uppercase',
      color: 'primary',
      top: '-5rem',
      fontSize: ['3rem', '4rem', '6rem', '7rem'],
      textShadow: '2px 2px 0px black'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  //  ? ========================
  //  ? ====  Parking page  ====
  //  ? ========================

  parking: {
    mt: '170px',
    background: 'none'
  },

  //  ? ========================
  //  ? ====  Parking page  ====
  //  ? ========================

  reservations: {
    minHeiht: '60vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3rem'
  },

  //  ? =============================
  //  ? ====  Private Room page  ====
  //  ? =============================

  privateroom: {
    width: '100%',
    maxWidth: 'unset',
    backgroundColor: 'transparent',
    padding: ['0rem', '0rem', '0rem', '0rem'],
    borderRadius: '0px',
    marginBottom: '0rem',
    '.innerMenuContainer': {
      padding: '3rem 1rem'
    },
    '.allInContainerWrapper': {
      backgroundColor: 'transparent'
    },
    '.menuSectionTitle': {
      display: 'none'
    },

    '.menuSectionDescription': {
      maxWidth: '767px',
      margin: '0rem auto 2rem',
      lineHeight: '1.75',
      fontSize: '1.25rem'
    },

    '.menuItemContainer, .menuItemContainerImgActive': {
      width: ['100%', '', 'calc(50% - 2rem)', 'calc(50% - 2rem)', 'calc(50% - 2rem)'],
      margin: ['', '', '1rem'],
      border: 'solid 1px black'
    },

    '.menuItemImageContainer': {
      height: '400px',
      width: '100%'
    },
    '.menuItemContentContainer ': {
      height: 'auto',
      padding: ['1rem', '2rem', '1rem', '2rem'],
      flexGrow: '1',
      alignItems: 'center'
      // justifyContent: 'center',
    },

    '.menuItemNamePriceContainer': {
      width: '100%'
    },

    '.menuItemName': {
      marginBottom: '2rem',
      width: '100%',
      fontSize: ['1.5rem', '', '2rem', '2.5rem'],
      borderBottom: '1px solid',
      paddingBottom: '0.5rem'
    },
    '.menuItemDescription': {
      width: '100%'
    },
    '.menuItemImgDefault, .menuItemImgDefaultContainer': {
      display: 'none'
    }
  }
}
